<template>
  <ComingSoon />
</template>

<script>
import ComingSoon from './components/ComingSoon.vue'

export default {
  name: 'App',
  components: {
    ComingSoon
  }
}
</script>

<style>
/* Vous pouvez ajouter des styles globaux ici */
</style>

